/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.ant-upload-list.ant-upload-list-text {
  display: none;
 }

.App-header {
  /* background-color: #282c34; */
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.prompt-textarea {
  width: calc(100vw - 250px);
}

#hasOpenTextField {
  background: rgba(0, 0, 0, 0.25);
}

#hasOpenTextField.ant-switch-checked {
  background: #1677ff;
}

.dashbaord {
  height: 100vh;
} 

.iub__us-widget {
  display: none !important;
}

.control-users-table th:first-child {
  min-width: 190px;
}

.control-users-table th:nth-child(6) {
  min-width: 126px;
}

.control-users-table th:nth-child(10) {
  min-width: 150px;
}

.control-users-table th:nth-child(12) {
  min-width: 162px;
}

.control-users-table th:nth-child(15) {
  min-width: 172px;
}

.control-users-table th:nth-child(16) {
  min-width: 158px;
}

.control-users-table th:nth-child(19) {
  min-width: 166px;
}

.control-users-table th:nth-child(20) {
  min-width: 192px;
}

.control-users-table th:nth-child(21) {
  min-width: 146px;
}

.control-users-table th:nth-child(22) {
  min-width: 186px;
}

.control-users-table th:nth-child(23) {
  min-width: 216px;
}

.control-users-table th:nth-child(24) {
  min-width: 128px;
}

.control-users-table th:nth-child(25) {
  min-width: 180px;
}

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */