@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e7c76;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b1afa7;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #7e7c76;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #b1afa7;
}

.custombutton .ant-upload.ant-upload-select {
  width: 100%;
}

.ant-btn:hover {
  outline: none !important;
  border-color: white !important;
}

.ant-btn {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
* {
  box-sizing: border-box;
}
body {
  background: #000000 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}
h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}
body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}
.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}

/* Customized CSS */

h1, h2, h3, h4, h5, h6, p, label, input{
  color: #1a1a1adf !important;
}

a.ant-typography {
  color: #4d4434 !important;
  font-family: "Fira Sans", sans-serif !important;
}
a.ant-typography:hover {
  color: #ffffff !important;
  font-family: "Fira Sans", sans-serif !important;
}
.logo-area {
  margin: 25px 0px
}

.app-logo{
  max-width:70%;
}

.bg-intro {
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.45) 51%), url(../public/il_cover.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.bg-login{
  background-color: rgba(255, 255, 255, 0.7);
  display: block;
  width: 100%;
  text-align: left;
}
.main-bg :not(.sidebar) .ant-btn{
  background-color: #d8ae5f;
}
.main-bg :not(.sidebar) .ant-btn:hover{
  background-color: #464646;
  color: #ffffff !important;
}

.main-bg :not(.sidebar) .ant-btn-default:disabled{
  background-color: #f5f5f5 !important;
  box-shadow: none;
  color: #00000040;
}
.ant-btn-default:disabled.bg-sidebarColor.text-white:hover{
  color: #00000040 !important;
  box-shadow: none;
}/*
.main-bg :not(.sidebar) .ant-btn:hover{
  border:none !important;
}*/
.submit-btn{
  background-color: #d8ae5f;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  font-size: 16px;
  cursor: pointer;
}
.submit-btn:hover{
  background-color: #836a49 !important;
}

.link-text {
  color:#4d4434
}

.link-text:hover{
  color:#ffffff
}

.footer-elt{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  bottom: 0;
  color: rgba(255,255,255,.8);
  letter-spacing: 1px;
  margin-top: auto;
}

.footer-items{
  padding: 20px 0px;
  background-color: #1f1f1f;
  width: 100%;
}

.footer-txt{
  color: #d8ae5f;
}

.footer-txt:hover{
  color: #836a49;
}

.logooos{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  width: 60%;
  margin: 20px auto;
}

.logooos-img {
  max-height: 130px;
  object-fit: cover;
  float: left;
}

/* navbar */
.nav-items{
  font-weight: normal;
}
.nav-item:hover{
  color: #d8ae5f;
}

/* sidebar */
.sidebar .text-white{
  font-size: 16px;
  font-weight: 400;
}
.sm\:bg-sidebarColor{
  background-color:#3f3f46 !important;
}
.sidebar .bg-sidebarColor {
  background-color:#3f3f46 !important;
}
.sidebar .bg-sidebarColor:hover, .ant-btn-default:hover {
  color:#d8ae5f !important;
}
.ant-dropdown-menu-item .ant-btn{
  background-color:#3f3f46 !important;
}
.sidebar .acc-item{font-size:12px;}

.btn-insight:hover{
  color: #3f3f46 !important;
}
.sidebar-top-buttons .bg-activebuttonColor:hover {
  color: #3f3f46 !important;
}
.sidebar-footer .plan-title {
  font-weight: 500;
  font-size: 16px;
}
.sidebar-footer .plan-name {
  font-weight: 400;
  font-size: 24px;
  color: #d8ae5f;
}
.sidebar-footer .plan-expires {
  font-weight: 400;
  font-size: 12px;
}
@media screen and (max-width: 600px) {
  .logooos, .footer-elt div {
    flex-direction: column;
    align-items: center;
  }

}